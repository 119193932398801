<template>
    <table class="tw-w-full">
        <thead>
            <tr class="tw-bg-blue-400 tw-text-white">
                <th
                    class="tw-px-2 tw-py-1 tw-whitespace-nowrap tw-border"
                    v-for="(c, index) in columns"
                    :key="index"
                >
                    {{ c.name }}
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(record, index) in resources">
                <tr :key="index"
									:class="{'tw-text-yellow-600': expandableItems[index] }"
									v-if="(expandableItems[index] && activeExpand == index) || activeExpand == null"
								>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border expandable-row"
                    >
                        <div
                            class="expandable-row__icon"
                            :class="{
                                'expandable-row__expanded':
                                    expandableItems[index]
                            }"
                            @click="toggleExpandedRow(index)"
                        >
                            <i
                                class="fa fa-caret-down"
                                v-if="expandableItems[index]"
                            />
                            <i class="fa fa-caret-right" v-else />
                        </div>
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.cleared_date }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.driver_code }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.driver_name }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.sex }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.contact_no }}
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.voucher_no }}
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border tw-text-right"
                    >
                        <ts-accounting-format
                            :value="record.amount_tobe_collected"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-text-right tw-px-2 tw-py-1 tw-border"
                    >
                        <div class="tw-flex tw-space-x-1">
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> $ </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_collected_usd,
                                            2
                                        )
                                    }}</span
                                >
                            </div>
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> KHR </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_collected_khr,
                                            0
                                        )
                                    }}</span
                                >
                            </div>
                        </div>
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-text-right tw-px-2 tw-py-1 tw-border"
                    >
                        <ts-accounting-format
                            currency="KHR"
                            :digit="0"
                            :value="record.taxi_fee"
                        />
                    </td>
                    <td
                        class="tw-whitespace-nowrap tw-text-right tw-px-2 tw-py-1 tw-border"
                    >
                        <div class="tw-flex tw-space-x-1">
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> $ </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_tobe_cleared_usd,
                                            2
                                        )
                                    }}</span
                                >
                            </div>
                            <div
                                class="tw-flex tw-justify-between tw-border tw-border-blue-400 tw-px-2 tw-rounded tw-space-x-1"
                            >
                                <span> KHR </span>
                                <span>
                                    {{
                                        $formatNumber(
                                            record.amount_tobe_cleared_khr,
                                            0
                                        )
                                    }}</span
                                >
                            </div>
                        </div>
                    </td>
                    <td class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-border">
                        {{ record.remark }}
                    </td>
                </tr>
								<tr v-if="expandableItems[index]" :key="'_'+index">
									<td :colspan="columns.length" class="tw-px-3">
										<TableDetail :record="record.details" />
										<div class="tw-w-full tw-h-4"></div>
									</td>
								</tr>	
            </template>
        </tbody>
    </table>
</template>

<script>
import TableDetail from './detail.vue'
export default {
    name: "driverClearCash",
    props: ["resources"],
		components: {
			TableDetail,
		},
    data() {
        return {
						activeExpand: null,
            expandableItems: []
        };
    },
    created() {
        this.resources.forEach((_, index) => {
            this.expandableItems[index] = false;
        });
    },
    computed: {
        columns() {
            return [
                { name: "", style: "width: 20px" },
                {
                    name: this.$t("driverClearCash.transactionDate"),
                    style: "width:30px"
                },
                { name: this.$t("driverClearCash.driverCode") },
                { name: this.$t("driverClearCash.driverName") },
                { name: this.$t("driverClearCash.sex") },
                { name: this.$t("driverClearCash.contactNo") },
                { name: this.$t("driverClearCash.voucherNo") },
                { name: this.$t("driverClearCash.amountToBeCollected") },
                { name: this.$t("driverClearCash.amountCollected") },
                { name: this.$t("driverClearCash.taxiFee") },
                { name: this.$t("driverClearCash.amountTobeClear") },
                { name: this.$t("driverClearCash.remark") }
            ];
        }
    },
    methods: {
        toggleExpandedRow(index) {
						if(!this.expandableItems[index]){
							this.activeExpand = index
						}else {
							this.activeExpand = null
						}
            this.$set(
                this.expandableItems,
                index,
                !this.expandableItems[index]
            );
        }
    }
};
</script>
