<template>
    <div>
        <ts-page-title
            :title="$t('companyCompensate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('companyCompensate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="tw-flex tw-items-center tw-space-x-3">
                        <a-button
                            type="primary"
                            @click="addNew"
                            v-if="$can('create-company-compensates')"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="false"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('companyCompensate.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('companyCompensate.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                    :expandable="true"
                >
                    <template v-slot="{ record }">
                        <td class="tw-whitespace-nowrap">
                            {{ record.transaction_date }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.reference_id }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.remark }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.user_name }}
                        </td>
                        <td class="tw-whitespace-nowrap tw-text-center">
                            <a
                                href="#"
                                class="tw-text-red-500"
                                @click.prevent="previewPdf(record)"
                            >
                                <i class="fas fa-file-pdf"></i>
                            </a>
                        </td>
                    </template>
                    <template v-slot:expand="{ record }">
                        <Details :resources="record.driver_clear_cashes" />
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
            <a-modal
                :title="$t('pdf')"
                width="50%"
                v-model="preview_pdf"
                :footer="null"
            >
                <ts-preview-pdf v-model="waiting" :src="src" />
            </a-modal>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import Details from "./components/driver-clear-cash.vue";

export default {
    name: "companyCompensateIndex",
    components: {
        Details
    },
    data() {
        return {
            preview_pdf: false,
            loading: false,
            waiting: false,
            src: ""
        };
    },
    computed: {
        ...mapState("finance/companyCompensate", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.finance.companyCompensate.search;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/companyCompensate/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "finance/companyCompensate/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("companyCompensate.transactionDate"),
                    style: "width:30px"
                },
                { name: this.$t("companyCompensate.#ref") },
                { name: this.$t("companyCompensate.remark") },
                { name: this.$t("companyCompensate.clearBy") },
                { name: this.$t("actions"), style: "width:40px" }
            ];
        },
        dateRange: {
            get() {
                return this.$store.state.finance.companyCompensate.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "finance/companyCompensate/SET_DATE_RANGE",
                    newValue
                );
            }
        }
    },
    methods: {
        ...mapActions("finance/companyCompensate", ["toPdf"]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/companyCompensate/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.$router.push({ name: "company-compensate-create" });
        },
        previewPdf(record) {
            this.preview_pdf = true;
            this.waiting = true;
            this.toPdf(record.jd_compensate_id)
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("finance/companyCompensate/RESET_STATE");
        next();
    }
};
</script>
