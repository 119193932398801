var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('companyCompensate.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('companyCompensate.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-3"},[(_vm.$can('create-company-compensates'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")]):_vm._e(),_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":false,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"tw-space-x-3"},[_c('a-tooltip',{attrs:{"title":_vm.$t('companyCompensate.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('companyCompensate.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading,"expandable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var record = ref.record;
return [_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.transaction_date)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.reference_id)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.remark)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(record.user_name)+" ")]),_c('td',{staticClass:"tw-whitespace-nowrap tw-text-center"},[_c('a',{staticClass:"tw-text-red-500",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.previewPdf(record)}}},[_c('i',{staticClass:"fas fa-file-pdf"})])])]}},{key:"expand",fn:function(ref){
        var record = ref.record;
return [_c('Details',{attrs:{"resources":record.driver_clear_cashes}})]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('a-modal',{attrs:{"title":_vm.$t('pdf'),"width":"50%","footer":null},model:{value:(_vm.preview_pdf),callback:function ($$v) {_vm.preview_pdf=$$v},expression:"preview_pdf"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src},model:{value:(_vm.waiting),callback:function ($$v) {_vm.waiting=$$v},expression:"waiting"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }